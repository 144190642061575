import React from 'react';
import logo from './patch_IFTL_vf.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <h1>Innovation Flight Test Lab</h1>
        <img src={logo} className="App-logo" alt="logo" />

        <div style={styles.buttonContainer}>
          {/* Bouton vers www.filgoods.iftl-ev.fr */}
          <a
            href="http://www.filgoods.iftl-ev.fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button style={styles.blueButton}>Aller sur www.filgoods.iftl-ev.fr</button>
          </a>

          {/* Bouton vers opti-tbm.iftl-ev.fr */}
          <a
            href="http://opti-tbm.iftl-ev.fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button style={styles.redButton}>Visiter opti-tbm.iftl-ev.fr</button>
          </a>

          {/* Bouton vers www.gra.iftl-ev.fr */}
          <a
            href="http://gra.iftl-ev.fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button style={styles.greenButton}>Naviguer vers www.gra.iftl-ev.fr</button>
          </a>
        </div>

      </header>
    </div>
  );
}

export default App;

const styles = {
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '20px',
  },
  blueButton: {
    backgroundColor: '#3498db', // Bleu mat
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '10px',
  },
  redButton: {
    backgroundColor: '#e74c3c', // Rouge mat
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '10px',
  },
  greenButton: {
    backgroundColor: '#27ae60', // Vert mat
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '10px',
  },
};
